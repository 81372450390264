.notification-message {
  width: 381px !important;
  height: 88px !important;
  background: #ffffff !important;
  border-radius: 3px !important;

  .error-msg {
    font-size: 16px !important;
    font-weight: 500;
    color: #ff5959;
    line-height: 16px;
    position: relative;
    top: 6px;
  }

  .success-msg {
    font-size: 14px !important;
    color: greenyellow;
  }

  .toast-des {
    font-size: 14px !important;
    display: table-cell;
  }
  .ant-notification-notice-close {
    background-color: #ffffff !important;
    padding-bottom: 20px;
  }

  .ant-notification-notice-content {
    position: relative;
    bottom: 24px;
  }
  .ant-notification-notice-description {
    position: relative;
    top: 4px;
  }
}
.error-msg-border {
  border: 0.5px solid #ff5959;
}

.success-msg-border {
  border: 1px solid greenyellow;
}