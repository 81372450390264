.risk-adjustment-container {
  background: #fff !important;
  padding: 10px !important;
  position: relative;
  z-index: 0;

  .ant-table-header {
    background: #fafafa !important;
  }

  .ant-table-container {
    padding-top: 3px;
  }

  .status-tags {
    font-weight: 600;
    padding: 5px 15px;
    border-radius: 10px;
    border-radius: 6px;
  }

  .status-tags-High {
    color: #e31d1c;
    background: #ffd9d7;
  }

  .status-tags-Low {
    color: #11a75c;
    background: rgba(29, 180, 105, 0.12);
  }

  .status-tags-Medium {
    color: #ffad0f;
    background: #ffeecd;
  }
}

.column-filters {
  text-align: right;
  .info-icon {
    padding: 8px 0px 0px 8px;
    cursor: pointer;
  }
  .filter-count-text {
    font-weight: 400;
    line-height: 23px;
    letter-spacing: -0.2px;
    margin: 0px;
    color: #251e48;
  }
  .filter-count {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    line-height: 23px;
    letter-spacing: -0.2px;
    margin: 0px;
    color: #a7ba4d;
  }
}

.selected-filters {
  display: flex;
  align-items: center;
  text-align: left;
  padding: 10px !important;
  padding-left: 0 !important;
  margin-bottom: 5px;
  .selected-filters-title-div {
    min-width: 51px;
    width: 51px !important;
    margin-right: 9px;
    .selected-filters-title {
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;
      color: #2e2e2e;
    }
  }

  .selected-filters-tags {
    overflow-x: auto;
    .selected-filter-tag {
      background: #e1e6f1;
      border-radius: 20px;
      border: none;
      padding: 5px 8px 5px 10px;
      font-weight: 600;
      font-size: 13px;
      line-height: 21px;
      color: #2e2e2e;

      .ant-tag-close-icon {
        margin-inline-start: 14.5px;
        color: #5b6e88;
        font-size: 13px;
      }
    }
    .clear-all-tags {
      border-radius: 20px;
      font-size: 13px;
      line-height: 20px;
      height: 31px;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.column-filter-overlay {
  .ant-popover-title {
    h3 {
      margin: 0px;
      line-height: 21px;
      font-size: 18px;
    }
    border-bottom: 1px solid #f5f5f5;
    padding: 10px 0;
  }
}

.lock-outer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}
.primary-filters {
  background: #fafafa;
  border: 1px solid #e1e1e1;
  padding: 3px 15px 3px 15px;

  .filters-container {
    padding: 12px 0px 12px 0px;
  }
  .apply-button {
    width: 69px !important;
    height: 36px !important;
  }
  .reset-button {
    border-radius: 4px !important;
    box-shadow: none;
    text-align: center !important;
    width: 69px !important;
    height: 36px !important;
  }

  .as-of-month {
    width: 85px;
  }

  .opportunity-score {
    width: 130px;
  }

  .risk-score {
    width: 75px;
  }

  .health-plan-label {
    width: 80px;
  }
  .datepicker-as-of-month {
    background: #ffffff;
    border: 0.8px solid #bcbcbc;
    border-radius: 4px;
    width: 179px;
    height: 38px;
  }
  .datepicker-as-of-month .ant-picker-suffix {
    color: #5b6e88;
    width: 16.67px;
    height: 16.67px;
  }
  .datepicker-as-of-month .ant-picker-input > input::placeholder {
    font-size: 15px;
    color: #2e2e2e;
  }
  .dropdown-year .ant-select-selector {
    background: #ffffff;
    border: 0.8px solid #bcbcbc;
    border-radius: 4px;
    width: 139px;
    height: 38px;
    font-weight: 1000;
    font-size: 15px;
    padding-top: 3px;
  }
  .dropdown-filters .ant-select-selector {
    background: #ffffff;
    border: 0.8px solid #bcbcbc !important;
    border-radius: 4px;
    width: 179px;
    height: 38px;
    font-weight: 500;
    line-height: 20px;
    font-size: 15px;
    padding-top: 5px;
    .ant-select-selection-item {
      height: 22px;
      line-height: 18px;
      z-index: 1;
      color: #2e2e2e;
      background-color: #f9fafb;
      border: 1px solid #d7dfe9;
    }
    .ant-select-selection-placeholder {
      color: #2e2e2e !important;
    }
  }
  .button-group {
    padding-left: 50px;
  }
}
.advance-filter {
  .filter-header {
    margin-top: 0.6rem;
    .filter-header-column {
      margin-top: 0.2rem;
    }
  }
  .filter-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
  }
  .apply-filter-btn {
    padding: 13px 23px;
    margin-top: 0.5rem;
  }

  .reset-filter-btn {
    border-radius: 3px !important;
    margin-right: 1rem;
    height: 28px !important;
  }
  .close-filter-btn {
    border-radius: 3px !important;
    height: 28px !important;
  }

  .ant-select-multiple {
    font-size: 15px !important;
    .ant-select-selector {
      padding: 5px 5px;
    }
    .ant-select-selection-overflow {
      margin-top: 4px;
    }
  }
  .ant-picker {
    padding: 8px 11px 8px;
    .ant-picker-input > input {
      font-size: 15px !important;
      border-color: #9f92de !important;
    }
    .ant-picker-suffix {
      color: #5b6e88;
      font-size: 1.1rem;
    }
    .ant-picker-clear {
      font-size: 1.1rem;
    }
  }
  .ant-picker-focused {
    border-color: #9f92de !important;
    box-shadow: none !important;
    outline: none !important;
  }

  :hover.ant-picker {
    border-color: #9f92de !important;
  }
  .ant-picker-input input::placeholder {
    color: #2e2e2e !important;
  }
  .ant-select .ant-select-selection-placeholder {
    color: #2e2e2e;
  }
  .ant-select-tree {
    color: #2e2e2e;
  }

  .ant-select-focused .ant-select-selector {
    box-shadow: none !important;
    outline: none !important;
  }
  .ant-picker-panel .ant-picker-footer > a {
    color: #5b4e98 !important;
  }
  .ant-select-arrow {
    font-size: 1rem;
    color: #8f8f8f;
  }

  .ant-select-tree-switcher {
    width: 10px !important;
  }
  .ant-select-selector .ant-select-selection-item {
    height: 22px;
    margin-top: 4px;
    line-height: 18px;
    z-index: 1;
    color: #2e2e2e;
    background-color: #f9fafb;
    border: 1px solid #d7dfe9;
  }
}
.header-search {
  width: 17vw;
  color: #99a6b7;
}
.select-check-box {
  .ant-checkbox + span {
    font-size: 14px;
  }
}

.active,
.inactive {
  color: #493bdc;
  &:hover {
    color: #493bdc;
  }
}

.health-plan.ant-btn {
  width: 179px !important;
}
.my-favorite {
  .icon-position {
    padding-left: 6px;
    color: rgba(0, 0, 0, 0.88) !important;
  }
  .favorite-option {
    cursor: pointer;
  }
}
.information-icon {
  .ant-popover-inner {
    background-color: #6f6db0;
  }
  .ant-popover-inner-content {
    color: #ffffff;
  }
  .ant-popover-arrow {
    --antd-arrow-background-color: #6f6db0;
  }
}
.my-favorite-tab {
  .favorite-title {
    font-weight: 600;
    font-size: 17px;
    line-height: 21px;
    letter-spacing: -0.01em;
    color: #2e2e2e;
  }
  .close-icon {
    float: right;
    cursor: pointer;
  }
  .reset-filter-btn {
    border-radius: 3px !important;
    margin-right: 1rem;
  }
  .favorite-footer {
    padding: 0.3rem;
  }
  .favorite-title-des {
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: #5c5f62;
    margin-right: 2rem;
  }
  .scrollbar {
    overflow-y: scroll;
    max-height: 285px;
    min-height: 285px;

    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-track {
      background: #e4e5ee;
    }
    &::-webkit-scrollbar-thumb {
      background: #c7c9d9;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
  .my-favorite-list-main {
    padding: 0.5rem;
    cursor: pointer;
  }

  .favorite-list-name {
    font-size: 15px;
    font-weight: 600;
    padding-bottom: 0.2rem;
  }
  .favorite-loader {
    left: 50%;
    position: absolute;
    text-align: center;
    top: 45%;
  }
  .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #6f6db0 !important;
  }
  .ant-tabs .ant-tabs-ink-bar {
    background-color: #6f6db0 !important;
  }

  .ant-tabs-tab :hover {
    color: #6f6db0 !important;
  }
  .ant-tabs .ant-tabs-tab {
    margin: 0 10px 0 25px;
    padding: 8px;
  }
  + .ant-tabs-tab {
    margin: 0 10px 0 25px;
  }

  .favorite-date {
    font-weight: 500;
    font-size: 12px;
    line-height: 19px;
    letter-spacing: -0.01em;
    color: #252525;
    margin-left: 0.35rem;
    vertical-align: top;
  }
  .favorite-share-user {
    margin-left: 11px;
    font-size: 12px;
    vertical-align: text-top;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 150px;
    display: inline-block;
    text-transform: capitalize;
  }
}
.my-favorite-tab-menu {
  .icon-position {
    padding-left: 5px;
  }

  .favorite-option-menu {
    cursor: pointer;
    color: rgba(0, 0, 0, 0.88) !important;
  }
}

.sketch-picker {
  z-index: 99 !important;
}

.filters-tooltip {
  .ant-tooltip-content {
    font-size: 11px;
  }
}

@media only screen and (min-width: 310px) and (max-width: 580px) {
  .col-width-100 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }

  .ant-popover-content {
    width: 27em !important;
    transform: translate(6em, 0px) !important;
  }
}
