/*Login and registration*/
.app-section {
  background-color: #eff1f9;
  height: 100vh !important;
  position: relative;
}

.login-container {
  height: 100%;
}

.col {
  padding-top: 30px;
}

.google-sign-btn {
  iframe {
    width: 100% !important;
    margin: -2px 0px !important;
    #container {
      padding: 2px 0px !important;
      width: 100% !important;
    }
  }
}

iframe #container,
.haAclf {
  width: 100% !important;
  padding: 0px !important;
}

/*Login button*/
.btn-primary {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  width: 100%;
  border-radius: 3px !important;
  background: #9f92de;
  border-color: #9f92de;
  align-items: center;
  border-radius: 3px;
  text-transform: capitalize;
}

/*Login form*/
.username .ant-form-item-required,
.password .ant-form-item-required {
  font-weight: 600 !important;
  font-style: normal;
  font-size: 15px !important;
  line-height: 20px;
  opacity: 0.85;
  display: flex;
  align-items: center;
  color: #0d0d0d !important;
}

.username .ant-form-item,
.ant-checkbox-wrapper,
.password .ant-form-item {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 16px !important;
}

.username .ant-input,
.password .ant-input {
  background: #ffffff;
  border: 1px solid #e1e6f1;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 18px 8px 12px;
  gap: 10px;
  box-sizing: border-box;
}
.ant-input-password {
  background: #ffffff;
  border: 1px solid #e1e6f1;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 18px 8px 12px;
  box-sizing: border-box;
}

.ant-form-item-required::before {
  margin-inline-end: 7px !important;
}

.rememberMe .ant-checkbox {
  background: #ffffff;
  border: 1px solid #9f92de;
  border-radius: 2px;
}

.forgotPasswordLink.ant-typography {
  font-size: 12px !important;
  font-weight: 500;
  line-height: 16px;
  color: #6451c4;
  &:hover {
    color: #6451c4;
  }
  &:active {
    color: #6451c4;
  }
}

.signUpLink.ant-typography {
  font-size: 14px !important;
  color: #0645ad;
  &:hover {
    color: #0645ad;
  }
  &:active {
    color: #0645ad;
  }
}

.divider.ant-divider {
  margin-top: 0;
  margin-bottom: 12px;
  padding: 0px 76px;
  color: #8f8f8f;
  .ant-divider-inner-text {
    color: #3c3738;
    letter-spacing: 1px;
    font-weight: 600;
  }
}

/*card*/
.card {
  background: #ffffff;
  border-radius: 3px;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
}
.login-to-acc {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 16px;
  color: #000000;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 375px) and (max-height: 667px) {
  .hide-for-small-screen {
    display: none !important;
  }
}

.social-login-buttons {
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  padding: 10px 13px;
  height: 22px;
  display: inline-block;
  cursor: pointer;
  margin: 0 5px;
}
