.accept-button {
  background: #9f92de;
  gap: 10px;
  color: white;
}

.button-group {
  padding-top: 15px;
  padding-left: 50px;
}

.card-style.ant-card.ant-card-bordered {
  border-radius: 3px !important;
  width: 380px;
  padding: 8px 0px 5px;
  gap: 8px;
  background: #ffffff;
  box-shadow: 0px 1px 0px #e4e8ee;
  align-items: flex-start;
}

.card-style.ant-card .ant-card-meta-description {
  color: #414141;
}

.card-style .ant-card-body {
  padding: 10px !important;
}

.popover-style {
  .ant-popover-inner-content {
    overflow-y: scroll;
    max-height: 27rem;
  }
  .ant-popover-content {
    padding-top: 15px !important;
  }
  .ant-popover-content,
  .ant-popover-inner {
    align-items: flex-start;
    filter: drop-shadow(0px 5px 15px rgba(0, 0, 0, 0.2));
    border-radius: 4px !important;
    padding: 0px;
  }

  .ant-popover-title {
    padding-left: 20px;
    padding-top: 4px;
    height: 35px;
  }

  .ant-popover .ant-popover-inner {
    padding: 0px;
  }
}
.desc-modal {
  padding-left: 10px;
}

::-webkit-scrollbar {
  width: 0px;
}

.human-readable-dates {
  padding-left: 50px;
  padding-top: 10px;
  color: #a5acb8;
}

.meta-style .ant-card-meta-title {
  font-size: 14px;
}
