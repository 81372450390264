.error-modal {
  text-align: center;

  .ant-modal-body {
    margin-top: -20px;
    padding-bottom: 10px;
  }

  .ant-modal-confirm-body {
    display: block !important;
  }

  .modal-header-icon {
    display: inline-flex;
    border-radius: 50%;
    margin-top: -60px;
    height: 85px;
    width: 85px;
    align-items: center;
    text-align: center;
  }

  .success {
    background-color: #a7ba4d;
    border: 5px solid #cad694;
  }
  .error {
    background-color: #fb4b4b;
    border: 5px solid #f6bdbd;
  }

  h1 {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 33px;
    align-items: center;
    color: #28293d;
    text-align: center;
  }

  p {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    color: #3c3738;
    letter-spacing: 1px;
  }

  // buttons

  .btn {
    min-width: 110px;
    padding: 10px 30px;
    background: #a7ba4d;
    border-radius: 4px;
    color: #fff;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    border: none;
    margin-left: 5px;
    margin-right: 5px;
    cursor: pointer;
  }
  .btn-success {
    background: #a7ba4d;
    color: #fff;
  }

  .btn-default {
    background: #d0deeb;
    color: #3c3738;
  }

  .btn-error {
    background: #fb4b4b;
    color: #fff;
  }
}
