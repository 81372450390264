@import url("https://fonts.googleapis.com/css2?family=Manrope");

html,
body {
  font-family: "Manrope", sans-serif !important;
}

.App {
  font-family: "Manrope", sans-serif !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media only screen and (max-width: 1120px) {
  .slidshow-left {
    width: 100%;
    max-width: 465px !important;
  }
}

@media only screen and (min-width: 0px) and (max-width: 929px) {
  .slidshow-left {
    display: none !important;
  }
  .hide-for-small-screen {
    display: none !important;
  }
}

@media only screen and (min-width: 930px) {
  .hide-for-large-screen {
    display: none !important;
  }
}

@media only screen and (max-width: 375px) and (max-height: 667px) {
  .app-section {
    background-color: #eff1f9;
    height: 128vh !important;
    position: relative;
    padding-top: 3em;
    zoom: 81% !important;
  }
  .ant-table-body {
    max-height: 400px !important;
  }
  .slidshow-left {
    display: none !important;
  }
}

.app-section {
  background-image: url(../src/assets/images/yuvo-link-background.svg);
  background-size: cover;
  background-position: center;
  height: 100vh;
  position: relative;

  .center-vh-card {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: auto;
    max-width: 1120px;

    .slidshow-left {
      width: 100%;
      max-width: 655px;
    }
    .login-form-right {
      width: 100%;
      max-width: 465px;
    }

    .logo-slider {
      position: absolute;
      top: -70px;
    }

    .card-form {
      height: 100% !important;
      padding-left: 40px;
      padding-right: 40px;
      border: none;
      border-radius: unset;
      .ant-card-body {
        padding: 24px 0px;
      }
    }

    .form-heading {
      font-family: "Manrope";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 16px;
      color: #000000;
    }

    .ant-form-item-label {
      font-family: "Manrope";
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      color: #334151;
    }

    .form-input {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 11px 20px 11px 13px;
      gap: 10px;
      height: 42px;

      background: #ffffff;
      border: 1px solid #e1e6f1;
      border-radius: 3px;
    }

    button {
      height: 36px;
    }

    .page-heading {
      font-size: 18px;
    }

    .page-title {
      font-size: 24px;
      font-weight: 500;
      color: #3a306c;
      margin: 0px 0px 15px;
    }

    .ant-carousel {
      height: 100%;
    }
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ant-checkbox,
.ant-checkbox-inner {
  width: 20px !important;
  height: 20px !important;
}

.ant-checkbox:hover,
.ant-checkbox + span {
  font-size: 16px;
  color: #334151;
  line-height: normal;
}

.ant-checkbox-checked .ant-checkbox-inner {
  width: 20px;
  height: 20px;
  border-radius: 3px;
}

.dashboard-page-heading {
  // font-family: "Public Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 24px;
  /* identical to box height, or 109% */

  display: flex;
  align-items: center;

  color: #251e48;
}

.ant-form-item-explain-error {
  line-height: 15px;
  font-size: 12px;
  padding-top: 5px;
  padding-bottom: 15px;
}

.haAclf {
  width: 100% !important;
  padding: 0px !important;
}
