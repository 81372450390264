.error-msg {
  font-size: 18px !important;
  margin: 0.5rem 0rem;
  font-weight: 500;
}

.success-msg {
  font-size: 18px !important;
  margin: 0.5rem 0rem;
  font-weight: 500;
}

.toast-des {
  font-size: 15px !important;
  margin-right: 1.5rem;
}

.error-msg-bg {
  width: 100% !important;
  border: 2px solid #ff8080;
  background: #f9f2f2 !important;
}

.success-msg-bg {
  width: 100% !important;
  border: 2px solid #6dc580;
  background: #f0f9f2 !important;
}

.toast-notification {
  .ant-notification-notice-content {
    margin-top: -1rem;
  }
  .ant-notification-close-icon {
    font-size: 20px !important;
  }
}
