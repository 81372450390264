.sub-dropdown {
  width: 179px;
  min-width: 179px;
  .ant-popover-inner {
    padding: 0px;
    padding-bottom: 3px;
    border-radius: 3px;
  }
  .ant-menu-item {
    margin-inline: unset;
    margin-block: unset;
    width: calc(100% - 0px);
    height: 32px;
    line-height: 32px;
    border-radius: 4px;
  }

  .ant-menu.ant-menu-vertical {
    border-inline-end: none;
  }
}
.empty-dropdown {
  width: 179px;
  min-width: 179px;
  .ant-popover-inner {
    padding: 12px;
  }
}

.searchOption {
  margin: 7px;
  width: fit-content;
  border-radius: 3px;
}

.dropdown-title {
  margin-inline-end: unset;
  width: 100%;
  font-size: 14px;
  border-radius: unset;
}

.ant-btn.dropdown-filters {
  background: #ffffff;
  border: 0.8px solid #bcbcbc !important;
  color: #8f8f8f;
  border-radius: 4px;
  max-width: 179px;
  width: 100%;
  height: 38px;
  font-weight: 500;
  line-height: 20px;
  font-size: 15px;
  padding-top: 3px;
  padding-left: 11px;

  &.readonly {
    cursor: not-allowed;
  }

  .ant-typography.selected-value {
    color: #2e2e2e;
    font-weight: 500;
    line-height: 20px;
    font-size: 15px;
    margin-bottom: unset;
    width: 100%;
    margin-right: 6px;
  }

  .ant-col {
    font-size: 15px;
    display: flex;
  }
}

.dropdown-filters {
  .ant-btn-default:disabled {
    max-width: 179px !important;
    width: 100% !important;
    height: 38px;
    border-radius: 4px;
    border: 0.8px solid #bcbcbc !important;
    background-color: #ededed !important;
  }
  .ant-typography.selected-value {
    color: #2e2e2e !important;
    font-weight: 500 !important;
    text-align: left;
    line-height: 20px;
    font-size: 15px;
    margin-bottom: unset;
    width: 100%;
    margin-right: 6px;
  }

  .ant-col {
    font-size: 15px;
    display: flex;
  }
}

.menu {
  max-height: 128px;
  overflow-y: auto;
  .ant-menu-item {
    padding-inline: unset;

    .radio-wrapper.ant-radio-wrapper {
      padding: 0px 8px;
    }
  }
  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
