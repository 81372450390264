.ant-layout-header {
  box-sizing: border-box;
  background: #ffffff !important;
  border-bottom: 1px solid #d9d9d9 !important;
}

.landing-sider {
  width: 65px !important;
  max-width: 65px !important;
  flex: 0 0 65px !important;

  overflow: auto !important;
  height: 100vh !important;
  position: fixed !important;
  z-index: 1;
  background: linear-gradient(
    180.07deg,
    #8254e5 -62.11%,
    #c458df 96.87%
  ) !important;

  .hamburger-icon {
    margin-left: 15px;
    margin-top: 0.7rem;
    cursor: pointer;
  }
  .side-nav {
    height: 100%;

    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    /*    background-color: white; */
    overflow-x: hidden;
    margin-top: 60px;

    .sidebar-menu-overlay {
      background: linear-gradient(
        180.07deg,
        #8254e5 -62.11%,
        #c458df 96.87%
      ) !important;
      .ant-menu-item {
        border-radius: 3px;
        border-bottom: 0.5px solid #d9d9d9;
        width: calc(100% - 23px);
        margin: 10px 10px;
        padding-left: 12px !important;
        font-size: 13px;
      }
      .ant-menu-submenu {
        .ant-menu-submenu-title {
          padding-left: 15px !important;
          .ant-menu-submenu-arrow {
            inset-inline-end: 3px;
            color: white;
          }
        }
      }
      .ant-menu-title-content {
        color: #4a5c75;
        margin-left: 11px;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 1px;
      }
      .ant-menu-item-selected {
        background-color: #0f0851;
      }
      .ant-menu-item svg {
        vertical-align: text-bottom;
      }
    }
  }

  .menu-tooltip {
    .ant-tooltip {
      .ant-tooltip-content {
        color: red !important;
        left: -100px !important;
      }
    }
    .ant-tooltip-content {
      color: red !important;
      left: -100px !important;
    }
  }

  .sidebar-move-right {
    width: 270px;
    transition: 0.5s;
  }

  .sidebar-move-left {
    width: 65px;
    transition: 0s;
    height: calc(100% - 55px);
  }

  .logout-btn {
    position: absolute;
    bottom: 0px;
    padding: 0rem 0rem 0.8rem 1.3rem;
    cursor: pointer;
    z-index: 1;
  }
  .logout-btn-overlay {
    position: absolute;
    bottom: 0px;
    padding: 0rem 0rem 0.8rem 1.2rem;
    cursor: pointer;
  }
}

.landing-sider.ant-layout-sider-collapsed {
  width: 55px !important;
  max-width: 55px !important;
  flex: 0 0 55px !important;
  min-width: 55px !important;
}

.logo-container {
  position: relative;
  width: 100% !important;
  padding: 10px !important;
  padding-left: 15px !important;
}

.sider-container {
  width: 100%;
  padding: 10px;
  height: 100vh;
  overflow-y: auto;
}

.ant-list-item {
  padding: 15px !important;
}
.list-item {
  display: inline-flex;
  align-items: center;
  width: 100%;
  color: #5b6e88;
}
.list-item span {
  margin-right: 10px;
  display: inline-flex;
  align-items: center;
  color: #4a5c75;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
}

// navbar menu icons
.navbar-menu-icons {
  display: inline-block;
  margin: 0;
  padding: 0 20px;
  li {
    display: inline-block;
    padding: 0 10px;
  }
}

.sidebar-menu.ant-menu-inline-collapsed {
  width: 55px !important;
  background: none;
}

.sidebar-menu {
  .ant-menu-submenu {
    .ant-menu-submenu-title {
      padding-left: 15px !important;
    }
  }
  .ant-menu-item {
    border-radius: 3px;
    border-bottom: 0.5px solid #d9d9d9;
    width: calc(100% - 22px);
    margin: 10px 7px;
    padding: 4px 1px 0px 11px !important;
  }

  .ant-menu-title-content {
    color: #4a5c75;
    margin-left: 13px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 1px;
  }
  .ant-menu-item-selected {
    background-color: #5b4e98;
  }
}
