.btn-container {
  width: 100%;
}
/*Login form*/
.username .ant-form-item-required {
  font-weight: 600 !important;
  font-style: normal;
  font-size: 15px !important;
  line-height: 20px;
  opacity: 0.85;
  display: flex;
  align-items: center;
  color: #0d0d0d !important;
}
