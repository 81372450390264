.label-outer {
  position: relative;

  .label {
    font-size: 15px;
    font-weight: 500;
    color: #2e2e2e;
    position: absolute;
    pointer-events: none;
    left: 12px;
    top: 8px;
    background-color: white;
    &.readonly {
      background: #ffffff !important;
    }
    &.disabled {
      background: #ededed !important;
    }
  }

  .label-float {
    top: -10px;
    font-size: 13px;
  }
}
