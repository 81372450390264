@import "./src/assets/styles/_variables"; // '~styles/variables';

.btn-container {
  font-size: 1rem;
  button,
  span {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  .btn,
  .ant-btn,
  .ant-btn[disabled] {
    font-size: 14px;
    line-height: 0;
    border-radius: 5px;
    border: none;
    outline: none;
    color: $button-default-color;
    padding: 10px 15px;
    cursor: pointer;
    background-color: $button-default-background-color;

    &:hover,
    &:focus {
      color: $button-default-color;
      border: none;
    }
    &:focus {
      box-shadow: 0.14em 0.14em 0.14em rgba(0, 0, 0, 0.1);
    }

    &.ant-btn-primary,
    &.primary {
      color: $button-primary-color;
      background-color: $button-primary-background-color;
      border: 1px solid $button-primary-border-color;
      box-shadow: none;
      &:hover:not([disabled]) {
        background-color: $button-primary-hover-background-color;
        cursor: pointer;
      }

      &:focus {
        background-color: $button-primary-focus-background-color;
      }
    }

    &.secondary {
      color: $button-default-color;
      background-color: $button-secondary-background-color;

      &:hover:not([disabled]) {
        background-color: $button-secondary-hover-background-color;
        cursor: pointer;
      }

      &:focus {
        background-color: $button-secondary-focus-background-color;
      }
    }

    &.tertiary {
      height: 32px;
      background-color: $button-default-color;
      border: 0.1em solid $button-tertiary-border-color;
      color: $button-tertiary-color;

      &:hover:not([disabled]) {
        cursor: pointer;
      }

      &:disabled {
        color: $button-tertiary-color;
        border: 0.1em solid #b3cbe7;
      }
    }

    &.quaternary {
      background-color: $button-quaternary-background-color;
      color: $button-quaternary-color;
      border: 0.1em solid $button-quaternary-border-color;
      border-radius: 0.4em;
      height: 1.7em;
      line-height: 1.7em;
      min-width: auto;
      width: auto;
      padding: 0em 0.6em;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover:not([disabled]) {
        background-color: #a7bbd8;
      }

      &.danger {
        background-color: $button-quaternary-danger-background-color;
        color: $button-quaternary-danger-color;
        border: 0.1em solid $button-quaternary-danger-border-color;
        &:hover:not([disabled]) {
          background-color: $button-quaternary-danger-hover-background-color;
        }
      }
    }

    &:disabled {
      opacity: 0.3;
      color: $button-default-disabled-color;
    }

    .btn-icon {
      padding: 0 0 0 1em;

      &.start {
        padding: 0 0.5em 0 0;
      }

      &.end {
        padding: 0 0 0 0.5em;
      }
    }
  }

  .btn:disabled,
  .ant-btn:disabled,
  .ant-btn[disabled]:disabled {
    opacity: 0.3;
    color: $button-default-color;
  }
}
