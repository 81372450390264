.global-search-bar {
  width: 206px;
  height: 36px;
  background: #ffffff;
  border: 1px solid rgba(159, 146, 222, 0.25);
  border-radius: 20px;
  position: relative;
  bottom: 5px;

  .ant-input-prefix {
    position: relative;
    right: 3px;
    top: 2px;
  }

  .ant-input {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 13px !important;
    line-height: 24px;
    letter-spacing: -0.2px;
    color: #7b7b7b;
    position: relative;
    right: 6px;
    top: 1px;
  }
  .ant-input-suffix {
    position: relative;
    left: 5px;
    top: 2px;
  }
}

@media only screen and (max-width: 595px) {
  .global-search-bar {
    display: none !important;
  }
}