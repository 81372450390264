.auth-container {
  height: 100%;
}
.submit-button {
  font-weight: 600 !important;
  font-size: 16px !important;
  max-width: 380px !important;
  width: 100%;
  max-height: 41px !important;
  border-radius: 6px !important;
  margin-top: 20px;
}

.verification-message {
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
  color: #3c3738;
}
.resend-message {
  font-size: 15px;
  line-height: 20px;
  font-weight: 600;
  color: #3c3738;
}
.verify {
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #0d0d0d;
}

.otpHeading {
  font-weight: 500;
  font-size: 22px;
  line-height: 25px;
  letter-spacing: -0.02em !important;
  color: #3a306c;
}

.countdown {
  .ant-statistic-content-value {
    color: #3a306c;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
  }
}

.loginToAcc {
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
  color: #0d0d0d;
  opacity: 0.85;
}
