.rename-modal {
  max-width: 343px !important;
  width: 100% !important;
  top: 0px !important;
  border-radius: 5px !important;

  .ant-modal-content {
    background: #ffffff;
    border-radius: 5px;
    padding: unset;

    .ant-modal-header {
      padding: 10px 15px;
      border-radius: 5px 5px 0px 0px;
      margin-bottom: unset;
      background: #6f6db0;
      .ant-modal-title {
        color: #ffffff;
        font-weight: 500;
        font-size: 16px;
        line-height: 28px;
      }
    }
    .ant-modal-body {
      padding: 0px 20px 7px 20px;
      border-radius: 0px 0px 5px 5px !important;
      font-weight: 600;
      font-size: 16px;
      line-height: 28px;
      color: #2e2e2e;

      p {
        margin: 15px 0;
      }
    }

    .ant-modal-footer {
      padding: 0em 1.3em 0em 0em;
    }
  }
  .favorite-name {
    width: 303px;
    height: 60px;
    background: #ffffff;
    border: 1px solid #afbaca;
    border-radius: 6px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.2px;
    color: #8897ae;
  }
  .favorite .ant-form-item {
    font-weight: 400;
    font-size: 14px !important;
    line-height: 16px !important;
  }

  .save-button,
  .cancel-button {
    width: 86.5px;
    height: 35px !important;
    bottom: 16px;
    left: 18px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    border-radius: 4px;
  }

  .ant-modal-close-x {
    background-color: #6f6db0;
    position: relative;
  }
}
