.view-edit-modal {
  max-height: 510px !important;
  max-width: 847px !important;
  width: 100% !important;
  top: 0px !important;
  border-radius: 2px;
  .ant-modal-content {
    padding: unset;
    border-radius: 2px !important;
    .ant-modal-header {
      padding: 10px 26px 10px 22px !important; 
      border-radius: 2px 2px 0px 0px !important;
      margin-bottom: unset;
      background: #5b4e98;
      .ant-modal-title {
        color: #ffffff !important;
        font-weight: 590;
        font-size: 18px;
        line-height: 28px;
        letter-spacing: -0.3px;
      }
    }
    .ant-modal-body {
      padding: 13px 20px 7px !important;
      border-radius: 0px 0px 2px 2px;
      overflow-y: scroll !important;
      height: 462px;

      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-track {
        background: #e4e5ee;
      }

      &::-webkit-scrollbar-thumb {
        background: #c7c9d9;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }
  }
  .datepicker {
    transition: none;
    background: #ffffff;
    border: 0.8px solid #bcbcbc;
    border-radius: 4px;
    max-width: 179px;
    width: 100%;
    height: 38px;
    &.readonly {
      background: #ffffff !important;
      .ant-picker-input > input {
        color: #414141;
      }
    }
    &.disabled {
      background: #ededed !important;
    }
    &.ant-picker-disabled .ant-picker-suffix {
      display: none;
    }
    .ant-picker-suffix {
      color: #5b6e88;
      width: 16.67px;
      height: 16.67px;
    }
    .ant-picker-clear {
      width: 16.67px;
      height: 16.67px;
    }
    .ant-picker-input > input {
      font-size: 15px;
      font-weight: 500;
      color: #414141;
      line-height: 20px;
    }
  }
  .dropdown-year {
    width: 100%;
    max-width: 83px;
    .ant-select-selector {
      transition: none;
      background: #ffffff;
      border: 0.8px solid #bcbcbc;
      font-style: normal;
      line-height: 20px;
      color: #2e2e2e;
      border-radius: 4px;
      width: 100%;
      height: 38px;
      font-weight: 500;
      font-size: 15px;
      padding-top: 3px;
    }
    &.readonly .ant-select-selector {
      background: #ffffff !important;
      color: #2e2e2e;
    }
    &.disabled .ant-select-selector {
      background: #ededed !important;
    }
    &.ant-input {
      background: #ffffff;
      border: 0.8px solid #bcbcbc;
      border-radius: 4px;
      width: 100%;
      height: 38px;
      font-weight: 1000;
      font-size: 15px;
      padding-top: 3px;
    }
  }
  .dropdown-filters {
    max-width: 183px;
    width: 100%;
    &.readonly.ant-select-disabled .ant-select-selector {
      background: #ffffff !important;
    }
    &.disabled.ant-select-disabled .ant-select-selector {
      background: #ededed !important;
    }
    .ant-select-selector {
      transition: none;
      background: #ffffff;
      border: 0.8px solid #bcbcbc !important;
      border-radius: 4px;
      width: 100%;
      min-height: 38px;
      font-weight: 1000;
      line-height: 20px;
      font-size: 13px;
      padding-top: 3px;
      padding: 3px 5px;
      .ant-select-selection-item {
        height: 22px;
        margin-top: 4px;
        line-height: 18px;
        z-index: 1;
        color: #2e2e2e;
        background-color: #f9fafb;
        border: 1px solid #d7dfe9;
      }
    }
    &.ant-input {
      background: #ffffff;
      border: 0.8px solid #bcbcbc !important;
      border-radius: 4px;
      width: 100%;
      height: 38px;
      font-weight: 1000;
      line-height: 20px;
      font-size: 15px;
      padding-top: 3px;
    }
  }

  .view-edit-modal-content {
    .title {
      color: #2e2e2e;
      font-size: 18px;
      line-height: 32px;
      font-weight: 600;
      font-style: normal;
    }
    .title-divider {
      margin: 0px !important;
    }
    .filter-fields {
      margin-top: 25px !important;
      .ant-checkbox-disabled .ant-checkbox-inner:after {
        border-color: #fff;
      }
      .manage-columns {
        padding-bottom: 10px;
        border-bottom: 1px solid #d9d9d9 !important;
        color: #414141;
        .manage-column {
          .ant-checkbox + span {
            color: #2e2e2e;
            font-weight: 500;
            font-size: 15px;
            line-height: normal;
          }
          .ant-checkbox-checked.ant-checkbox-disabled .ant-checkbox-inner {
            background: #5b4e98;
            border-color: #5b4e98;
          }
        }
      }
    }
  }
}
