/*Login failed modal*/
.modal-style {
  width: auto !important;
  .ant-modal-confirm-title {
    color: #fb4b4b !important;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
  }

  .ant-modal-content {
    background: #ffffff !important;
    border: 0.7px solid #fb4b4b;
    border-radius: 3px;
    height: auto !important;
  }
  .ant-modal-confirm-content {
    font-size: 14px !important;
    line-height: 16px !important;
    font-weight: 400 !important;
    color: #3c3738 !important;
    margin-top: 20px !important;
    margin-bottom: 20px;
  }
  .reset-password {
    float: right;
    color: #9f92de;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
  }
  .ant-modal-close {
    background-color: #ffffff !important;
  }
  .ant-modal-confirm-body {
    display: block !important;
  }
  .ant-modal-confirm-title + .ant-modal-confirm-content {
    max-width: calc(100%) !important;
  }
}
