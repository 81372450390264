.user-form {
  background: #fff !important;
  padding: 10px !important;
  margin-bottom: 5px;
  .user-form-header {
    height: 45px;
  }
  .user-form-header-text {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #1c1c28;
  }
  .form-input {
    padding: 0.6rem 0.7rem 0.6rem 0.7rem;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: #1c1c28;
  }
  .form-input-number {
    padding: 0.6rem 0.7rem 0.6rem 0.7rem;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: #1c1c28;
    margin-left: 12px;
  }
  .input-box-number {
    margin-right: 1rem;
    .ant-form-item-explain-error {
      margin-left: 11px;
    }
  }
  .send-btn {
    margin-right: 1rem;
    padding: 18px 15px;
  }
  .reset-btn {
    padding: 18px 15px;
    border-radius: 4px;
  }
  .input-box {
    margin-right: 1rem;
  }

  .ant-form-item-required span {
    display: none;
  }

  .ant-form-item-required:after {
    display: none;
  }
  .label-position {
    left: 26px !important;
  }
  .ant-form-item-label {
    margin-top: 4px;
  }
}

.risk-adjustment-container {
  background: #fff !important;
  padding: 10px !important;
}

.header-search {
  width: 17vw;
  color: #99a6b7;
}
.select-check-box {
  .ant-checkbox + span {
    font-size: 14px;
  }
}

.title-bar {
  text-align: right;
  background: #fff !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  margin-bottom: 5px;
}

.table-title {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
}
.failed {
  padding: 2px 8px;
  background-color: #fff5f4;
  color: #fb4b4b;
  border-radius: 6px;
  .bullet {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #fb4b4b;
    display: inline-block;
    margin-right: 4px;
  }
}

.delivered {
  padding: 2px 8px;
  background-color: rgba(29, 180, 105, 0.12);
  color: #1db469;
  border-radius: 6px;
  .bullet {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #1db469;
    display: inline-block;
    margin-right: 4px;
  }
}

.actions {
  width: 99px;
  list-style-type: none;
  margin: 0;
  padding-left: 0;
  li:last-child {
    padding-top: 8px;
  }
  li:not(:last-child) {
    border-bottom: 1px solid gray;
    padding-bottom: 8px;
  }
}

.action-btn {
  background-color: white;
  border: none;
  font-family: inherit;
  font-size: 14px;
  cursor: pointer;
}
