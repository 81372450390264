.wildcard-search-dropdown {
  width: 136px;
  height: 34px;

  .search-icon {
    height: 14px;
    width: 14px;
    color: #4a5c75;
    padding-bottom: 17px;
    pointer-events: visible;
  }
  .search-input {
    border: 1px solid #cccccc;
    border-radius: 4px;
    color: #8f8f8f;
    font-size: 13px;
    line-height: 22px;
    height: 40px;
  }
  .ant-input {
    font-weight: 400;
    font-family: "Inter";
    font-style: normal;
  }
}

.external-user-search {
  width: 179px;
  height: 32px;

  .search-icon {
    height: 14px;
    width: 14px;
    color: #4a5c75;
    padding-bottom: 17px;
    pointer-events: visible;
  }
  .search-input {
    border: 1px solid #cccccc;
    border-radius: 4px;
    color: #8f8f8f;
    font-size: 13px;
    line-height: 22px;
  }
  .ant-input {
    font-weight: 400;
    font-family: "Inter";
    font-style: normal;
  }
}
