.yuvo-table {
  .ant-table-body {
    overflow: auto !important;
  }
  .ant-table-cell:before {
    background-color: #fff !important;
  }
  .ant-table-cell:hover {
    background-color: #fff !important;
  }
  th,
  td {
    padding: 5px 8px !important;
  }
  td {
    border: none !important;
  }
  .ant-table-thead {
    .ant-table-cell {
      background-color: #fff;
      color: #1f1f1f;
      border: none;
      border-bottom: 1px solid #ae9dd7;
    }
  }

  .ant-table-cell .ant-checkbox {
    margin-top: -5px !important;
  }

  .ant-table-tbody {
    .ant-table-row,
    .ant-table-cell-fix-left-last {
      background-color: #fff !important;
    }

    > tr > td {
      border-top: none !important;
    }
  }

  .ant-table-column-sorter {
    display: none;
  }
}

.yuvo-table-pagination {
  .ant-pagination-item {
    margin-right: 8px !important;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #000000;
    height: 30px !important;
    padding: 4px !important;

    &:not(.ant-pagination-item-active) {
      border-color: #e1e6f1 !important;
    }
  }

  .ant-pagination-prev {
    margin-right: 10px !important;
  }

  .ant-pagination-next,
  .ant-pagination-prev {
    height: 30px !important;
    padding: 2px 10px;
    border: 1px solid rgb(139, 132, 171) !important;
    border-radius: 5px;
  }

  a {
    color: #232323 !important;
  }

  .ant-pagination-disabled {
    border: 1px solid #ccc !important;
    a {
      color: #ccc !important;
    }
  }
  .ant-pagination-item-active {
    background-color: #5b4e98 !important;
    border: 1px solid #5b4e98 !important;

    &:hover {
      background-color: #6f6db0 !important;
      border: 1px solid #6f6db0 !important;

      > a {
        color: #ffffff !important;
      }
    }

    > a {
      color: #ffffff !important;
    }
  }

  .ant-select-selector {
    border: 1px solid #e1e6f1 !important;
    height: 30px !important;
    text-align: center;
    padding: 4px !important;
  }
}
