.delete-modal {
  max-width: 100% !important;

  .ant-modal-content {
    height: 100%;
    padding: 20px 10px 10px 15px;
    border-radius: 4px;
    background: #ffffff;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);

    .ant-modal-header {
      padding: 5px 5px 0px 5px;
      .ant-modal-title {
        font-weight: 600 !important;
        font-size: 18px !important;
        line-height: 28px !important;
        color: #28293d !important;
        position: relative;

        .title {
          padding-left: 13px;
          position: relative;
          bottom: 8px;
        }
      }
    }
    .ant-modal-body {
      padding: 0px;
      width: 100%;
      height: 100%;
      font-size: 16px;
      line-height: 24px;
      padding-left: 47px;
    }
    .ant-btn-tertiary {
      background: #ffe5e5;
      color: #ff3b3b;
      border-radius: 4px;
      width: 78px;
      height: 36px;
      border: none;
      right: 6px;
    }
    .ant-btn-default {
      background: #ff3b3b;
      border-radius: 4px;
      font-size: 14px;
      width: 76px;
      height: 36px;
    }
  }
}
