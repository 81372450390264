.ant-btn-default:not(:disabled):hover {
  border-color: #cccccc;
}

.ant-input:hover {
  border-color: #cccccc;
}

.search-outer {
  display: flex;
  align-items: center;
  height: 35px;
  width: 200px;
  position: relative;
}

.btn-search {
  height: 100%;
  width: 50px;
  border: none;
  background-color: white;
  box-shadow: none;
  position: absolute;
  top: 10px;
  right: 0;
}

.btn-search-expanded {
  background-color: #ecf0fa;
  border-top: 1px solid #cccccc;
  border-right: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
  border-left: none;
  border-radius: 0 4px 4px 0;
}

.input-search {
  height: 100%;
  display: none;
  background-color: #ecf0fa;
  border-top: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
  border-left: 1px solid #cccccc;
  border-right: none;
  border-right: 0px;
  border-radius: 4px 0 0 4px;
  position: absolute;
  top: 10px;
  right: 50px;
}

.input-search-expanded {
  display: inline-block;
}
