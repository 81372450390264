.slide {
  background-size: cover;
  background-position: center;
}

.slick-list {
  height: 100% !important;
  div {
    height: 100% !important;
  }
}

.slide-one {
  background-image: url(../../../assets/images/slider-one.png);
}

.slide-two {
  background-image: url(../../../assets/images/slider-two.png);
}

.slide-three {
  background-image: url(../../../assets/images/slider-three.png);
}

.slide-four {
  background-image: url(../../../assets/images/slider-four.png);
}

.corousel-content {
  padding-left: 30px;
  color: white;
}

.slide-title {
  font-weight: 700;
  font-size: 35px;
}

.slider-hr {
  max-width: 90px;
  margin-left: 0;
  border: 2px solid white;
}

.slide-description {
  font-weight: 500;
  font-size: 17px;
}

.ant-carousel .slick-dots li.slick-active button {
  width: 25px;
  height: 10px;
  border-radius: 6px;
  background-color: #d5db30 !important;
  margin-right: 20px !important;
}

.ant-carousel .slick-dots li button {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: white !important;
}
