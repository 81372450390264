/*Login and registration*/
.app-section {
  background-color: #eff1f9;
  height: 100vh;
  position: relative;
  padding-top: 3em;
}
.col {
  padding-top: 30px;
}

/*Login button*/
.btn-primary {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  width: 100%;
  border-radius: 3px !important;
  background: #9f92de;
  border-color: #9f92de;
  align-items: center;
  border-radius: 3px;
  text-transform: capitalize;
}

/*Login form*/
.username .ant-form-item-required,
.password .ant-form-item-required {
  font-weight: 600 !important;
  font-style: normal;
  font-size: 15px !important;
  line-height: 20px;
  opacity: 0.85;
  display: flex;
  align-items: center;
  color: #0d0d0d !important;
}

.username .ant-form-item,
.ant-checkbox-wrapper,
.password .ant-form-item {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 16px !important;
}

// .ant-checkbox-wrapper{
//    align-items: center !important;
// }
.username .ant-input,
.password .ant-input {
  background: #ffffff;
  border: 1px solid #e1e6f1;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 18px 8px 12px;
  gap: 10px;
  box-sizing: border-box;
}
.ant-input-password {
  background: #ffffff;
  border: 1px solid #e1e6f1;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 18px 8px 12px;
  box-sizing: border-box;
}
.rememberMe .ant-checkbox {
  background: #ffffff;
  border: 1px solid #9f92de;
  border-radius: 2px;
}

.forgotPasswordLink.ant-typography {
  font-size: 12px !important;
  font-weight: 500;
  line-height: 16px;
  color: #6451c4;
  &:hover {
    color: #6451c4;
  }
  &:active {
    color: #6451c4;
  }
}

.signUp-link.ant-typography {
  font-size: 14px !important;
  color: #0645ad;
  &:hover {
    color: #0645ad;
  }
  &:active {
    color: #0645ad;
  }
}

.divider.ant-divider {
  margin-top: 0;
  margin-bottom: 12px;
  padding: 0px 76px;
  color: #8f8f8f;
  .ant-divider-inner-text {
    color: #3c3738;
    letter-spacing: 1px;
    font-weight: 600;
  }
}

/*card*/
.card {
  background: #ffffff;
  border-radius: 3px;
  margin-top: 50px;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
}
.welcome-text {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 16px;
  color: #000000;
  display: flex;
  align-items: center;
}

.create-acc {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 16px;
  color: #000000;
  display: flex;
  align-items: center;
}
.ant-input-disabled {
  color: black !important;
}

.app-section .center-vh-card .logo-slider {
  top: -15% !important;
}

:host {
  .app-section .center-vh-card .ant-carousel {
    padding-top: 1em !important;
  }
}