$color_1: #414141;
$font-family_1: "Manrope";

.header {
  box-sizing: border-box;
  align-items: center;
  height: 10%;
  background: #ffffff;
  border-bottom: 1px solid #d9d9d9;
}

.page-header.ant-layout-header {
  padding-inline-start: 0px;
  padding-inline-end: 10px !important;
  // padding-inline-end: 50px;
  .sidebar-collpase-icon-outer {
    position: absolute;
    .sidebar-collpase-icon {
      position: relative;
      left: -50%;
    }
  }
}

.ant-col {
  .notificationCol {
    position: absolute;
    left: 82.63%;
    right: 16.94%;
    top: 4.77%;
    bottom: 94.84%;
    border: 1.5px solid #5b6e88;
  }
}
@media (max-width: 575.98px) {
  .ant-col {
    .notificationCol {
      align-items: center;
    }
  }
}

.page-header {
  .ant-badge-count {
    min-width: 13px !important;
    height: 13px !important;
    font-size: 10px !important;
    line-height: 14px !important;
    box-shadow: none !important;
    margin-right: 5px;
  }

  .bell-icon,
  .cogs-icon {
    position: relative;
    top: 2px;
  }
}

@media only screen and (max-width: 400px) {
  .navbar-menu-icons {
    display: inline-block;
    margin: 0;
    padding: 0px 1px;
  }
  .logo-div-width {
    width: 40% !important;
  }
  .div-width {
    width: 60% !important;
  }
}
